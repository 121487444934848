@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Changa+One:ital@0;1&family=Corinthia:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Caudex:ital,wght@0,400;0,700;1,400;1,700&family=Josefin+Slab:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bruno+Ace+SC&family=Caudex:ital,wght@0,400;0,700;1,400;1,700&family=Jomolhari&family=Josefin+Slab:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Chango&family=Exo:ital,wght@0,100..900;1,100..900&family=Expletus+Sans:ital,wght@0,400..700;1,400..700&family=Inter:wght@100..900&family=Jost:ital,wght@0,100..900;1,100..900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200..800&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sen:wght@400..800&family=Vazirmatn:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cagliostro&family=Cantarell:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Brawler:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Changa:wght@200..800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  /* box-shadow: 0px 4px 20px 0px #00000040; */
  border-bottom: 1px solid #a1abff;
  background: #d9d9d9;
}

.nav-link {
  font-family: "Changa One", sans-serif;
  font-size: 22px !important;
  font-weight: 400;
  color: #a1abff !important;

  margin: 0 15px;
}

.nav-item {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  top: calc(100% + -3px);
  right: 0;
  background-color: #010033 !important;
  border-top: none;
  z-index: 1;
  opacity: 0; /* Hide initially */
  transform: translateY(-10px); /* Initial position above */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth transition */
  width: 250px !important;
  padding-left: 10% !important;
  padding-right: 10% !important;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  padding: 10px;
  border-bottom: 1px solid #6e6e6e;
}

.dropdown-menu ul li:last-child {
  border-bottom: none;
}

.dropdown-menu ul li a {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
  display: block;
}

.nav-item:hover .dropdown-menu {
  display: block;
  opacity: 1; /* Show with smooth transition */
  transform: translateY(1px); /* Adjust dropdown position */
  border: none;
}

.nav-item:hover .dropdown-menu ul li:hover {
  background-color: #032498; /* Background color on hover */
}

.bi-person-circle {
  font-size: 30px;
}
.bi-box-arrow-right {
  font-size: 20px; 
  padding-left: 3%;
}

.active {
  color: #7885fc !important;
}

.nav-link:hover {
  color: #7885fc !important;
}

.first-btn {
  border: 0;
  background: linear-gradient(90deg, #2e0259 0%, #032498 100%);
  border-radius: 25px;
  color: white;
  font-family: "Changa One", sans-serif;
  font-size: 22px;
  font-weight: 400;
  padding: 8px 35px;
}

.first-btn:hover {
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}

.main {
  padding-top: 4%;
  padding-bottom: 4%;
  background-image: url("../public/assets/Rectangle\ 1.png");
  background-size: cover;
}

.main-row {
  padding-left: 4%;
  padding-right: 4%;
  justify-content: space-between;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-content h2 {
  font-family: "Courgette", sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-align: left;
  color: #e9b7fc;
  text-shadow: 2px 2px 15px #ff64ef;
}

.main-content h1 {
  font-family: "Changa One", sans-serif;
  font-size: 78px;
  font-weight: 400;
  line-height: 82.84px;
  text-align: left;
  color: #fff;
}
.main-img img {
  width: 100%;
}

.main-button button {
  background: linear-gradient(90deg, #2e0259 0%, #032498 100%);
  border: 0;
  color: #fff;
  font-family: "Changa One", sans-serif;
  font-size: 22px;
  font-weight: 400;
  padding: 10px 30px;
  border-radius: 25px;
  margin-top: 8%;
}

.main-button button:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}
@media (max-width: 500px) {
  .nav-fluid {
    padding: 0 20px !important;
  }
  .navbar-nav {
    margin-left: 0;
  }
  .detail-header h1 {
    font-size: 54px !important;
  }
  .event-c h3 {
    margin-top: 3%;
  }
  .event-card h4 {
    margin-top: 5%;
  }
  .event-card h1 {
    padding-bottom: 3% !important;
  }
  .event-card h5 {
    margin-top: 10%;
    font-size: 24px;
  }
  .calendar {
    right: 23% !important;
  }
  .abs-card {
    border-bottom: 1px solid #7d7c7c;
    border-right: 0 !important;
  }
  .event-detail {
    padding-top: 10% !important;
  }
  .facilities {
    flex-direction: column-reverse;
  }
  .user-cards {
    padding-bottom: 5%;
  }
  .signin-left h1 {
    font-size: 60px !important;
  }
  .signin-right {
    border-left: 0 !important;
  }
  .sign-item {
    margin-bottom: 4%;
  }
  .confirm h1 {
    font-size: 32px !important;
  }
}

.footer {
  padding-top: 4%;
  background: #000;
}

.footer-content p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  color: #fff;
  padding-top: 3%;
  padding-bottom: 3%;
}

.footer-content h6,
.footer-list h6 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  padding-top: 3%;
  padding-bottom: 3%;
}

.footer-imgs img {
  padding-right: 5%;
}

.footer-list h3 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-top: 3%;
  padding-bottom: 5%;
}

.footer-list li {
  list-style: none;
  padding-bottom: 3%;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
}

.footer-list li:hover {
  color: #ff6363;
  cursor: pointer;
}

.footer-group {
  background: transparent;
  border: 1px solid #ffffff;
  width: 100%;
  border-radius: 10px;
  padding: 1%;
}

.footer-group input {
  background: transparent;
  border: 0;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
}

.footer-group input::placeholder {
  color: #b6b5b5;
}

.footer-group input:focus {
  box-shadow: none;
  outline: 0;
  color: #fff;
}

.footer-append span {
  background: #ff6363;
  margin: 1%;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  border: 1px solid #ff6363;
  cursor: pointer;
}

.footer-row2 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 19.69px;
  text-align: center;
  color: #fff;
  margin-top: 3% !important;
  padding-top: 3%;
  border-top: 1px solid #ffffff;
}

.footer-social {
  margin-top: 6%;
}

.footer-social i {
  padding: 5px 8px;
  background: #fff;
  border-radius: 50%;
  margin-right: 4%;
  cursor: pointer;
}

.bi-facebook:hover {
  color: #45c4e9;
}
.bi-twitter:hover {
  color: #03bff8;
}
.bi-instagram:hover {
  color: #ff6363;
}

.abs-cards {
  background: #000;
  padding-bottom: 4%;
}

.abs-row {
  background: #d9d9d91a;
  box-shadow: 0px 4px 25px 5px #d9d9d940;
  border-radius: 16px;
  padding: 2%;
}

.abs-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Barlow", sans-serif;
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  padding: 2%;
  border-right: 1px solid #7d7c7c;
}

.abs-card1 {
  border-right: 0;
}

.concert {
  padding: 3%;
  background: linear-gradient(79.84deg, #000000 0%, #010033 99.65%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.concert-heading h1 {
  background-image: linear-gradient(90deg, #2e0259 0%, #dc78ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: "Audiowide", sans-serif;
  font-size: 40px;
  font-weight: 400;
  text-align: center;
}

.concert-cards {
  margin: 4rem 0rem;
  position: relative;
  /* margin-top: 1rem; */
  padding-left: 5rem;
  padding-right: 5rem;
}
.user-cards {
  /* margin: 4rem 0rem; */
  position: relative;
}

.arrow-right,
.arrow-left {
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: transparent;
  position: absolute;
  top: 47%;
  z-index: 2;
  cursor: pointer;
}
.arrow-right1,
.arrow-left1 {
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: transparent;
  position: absolute;
  top: 100%;
  z-index: 2;
  cursor: pointer;
}

.arrow-right span,
.arrow-left span {
  font-size: 2rem;
  font-weight: 500;
}
.arrow-right1 span,
.arrow-left1 span {
  font-size: 2rem;
  font-weight: 500;
}

.arrow-left {
  left: 2rem;
}

.arrow-right {
  right: 2rem;
}
.arrow-left1 {
  left: 45%;
}

.arrow-right1 {
  right: 45%;
}
.review-card {
  background: #fff;
  padding: 1.5rem !important;
  margin: 1rem !important;
  /* width: 40%; */
}

.concert-card {
  padding: 2rem;
  background: transparent;
}
.user-card {
  padding: 5%;
}

.user-card1 {
  background: #010018bf;
  border-radius: 10px;
  padding: 5%;
}

.user-card1 h3 {
  font-family: "Jost", sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #fff;
}
.user-name i {
  color: #fad829;
}

.user-card1 p {
  font-family: "Jost", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26.01px;
  color: #c1bfbf;
}

.concert-card1 {
  border: 3px solid #dc78ff;
  background: #d9d9d900;
  box-shadow: 15px 15px 10px 0px #dc78ff;
  border-radius: 10px;
  padding: 5%;
}

.concert-card1 h4 {
  font-family: "Caudex", sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  color: #a1abff;
}

.concert-card1 h1 {
  font-family: "Bruno Ace SC", sans-serif;
  font-size: 38px;
  font-weight: 400;
  line-height: 45.85px;
  text-align: left;
  color: #fff;
  display: -webkit-box;         
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 3;        
  overflow: hidden;            
  text-overflow: ellipsis;      
  height: calc(46px * 3);    
  line-height: 1.2em;

}
.concert-card1 p {
  font-family: "Josefin Slab", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #fff;
}

.concert-card1 h6 {
  font-family: "Jomolhari", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.concert-card1 span {
  font-family: "Josefin Slab", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.concert-button button {
  margin-top: 4%;
  padding: 8px 25px;
  background: linear-gradient(90deg, #d9d9d9 0%, #737373 100%);
  border-radius: 25px;
  border: 0;
  font-family: "Barlow", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #010033;
}

.concert-button button:hover {
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}

/* .slick-active {
  padding: 5px;
} */

.slick-dots {
  display: none !important;
}

@media (max-width: 1025px) {
  .arrow-left {
    left: 0rem;
  }
  .arrow-right {
    right: 0rem;
  }
  .dropdown-menu{
    left: 0;
  }
}

@media (max-width: 768px) {
  .arrow-left {
    left: -1rem;
    top: 45%;
  }
  .arrow-right {
    right: -1rem;
    top: 45%;
  }
  .arrow-left1 {
    top: 92%;
    left: 37%;
  }
  .arrow-right1 {
    top: 92%;
    right: 37%;
  }
  .live-c h1{
    text-align: center;
  }
  .live-c h2{
    text-align: center;
  }
  .live-c div{
    display: flex;
    justify-content: center;
  }

  .high-col h2 {
    font-size: 36px !important;
  }
}
@media (max-width: 450px) {
  .arrow-left {
    left: 37%;
    top: 100%;
  }
  .arrow-right {
    right: 37%;
    top: 100%;
  }
  .concert-cards {
    padding-left: 1%;
    padding-right: 1%;
  }
}
@media (max-width: 370px) {
  .arrow-left {
    left: 37%;
    top: 100%;
  }
  .arrow-right {
    right: 37%;
    top: 100%;
  }
  .high-col h2 {
    font-size: 32px !important;
  }
}

.highlight {
  padding: 2%;
  padding-bottom: 4%;
  background: linear-gradient(83.22deg, #2e0259 0%, #010033 100%);
}
.highlight h1 {
  font-family: "Audiowide", sans-serif;
  font-size: 28px;
  font-weight: 400;
  text-align: left;
  color: #ff6363;
}

.high-col h2 {
  font-family: "Chango", sans-serif;
  font-size: 42px;
  font-weight: 400;
  line-height: 50.86px;
  text-align: left;
  color: #fff;
}

.high-col p {
  font-family: "Josefin Slab", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #fff;
}


.high-card {
  padding: 3%;
  background: #ff636380;
  border: 3px solid #ff6363;
  border-radius: 16px;
  margin-bottom: 3%;
}

.high-card p {
  font-family: "Jost", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28.9px;
  text-align: left;
  color: #fff;
  padding-top: 2%;
}

.about,
.news {
  background: linear-gradient(77.66deg, #000000 0%, #010018 100%);
  padding: 4%;
}
.about h1 {
  font-family: "Audiowide", sans-serif;
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 3%;
  background-image: linear-gradient(90deg, #2e0259 0%, #dc78ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.about-img img {
  width: 100%;
}

.about-left h2 {
  font-family: "Advent Pro", sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.27px;
  text-align: left;
  color: #fff;
}

.about-left p {
  font-family: "Jost", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28.9px;
  text-align: left;
  color: #c1bfbf;
  padding-top: 3%;
  padding-bottom: 3%;
}

.about-list li {
  list-style: none;
  font-family: "Jost", sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 2%;
  color: #dc78ff;
}

.about-list li i {
  margin-right: 2%;
}

.about-button button {
  padding: 8px 30px;
  border-radius: 25px;
  background: linear-gradient(90deg, #230a23 0%, #032498 100%);
  border: 2px solid #230a23;
  color: #fff;
  font-family: "Barlow", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-top: 4%;
}

.about-button button:hover {
  border: 2px solid #fff;
  background: transparent;
  transition: all 0.5s ease-in-out;
}

.news-img img {
  width: 100%;
  border-radius: 10px;
}

.news-header h3 {
  font-family: "Advent Pro", sans-serif;
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  color: #fff;
}

.news-header h1 {
  font-family: "Audiowide", sans-serif;
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  /* padding: 2%; */
  background-image: linear-gradient(90deg, #2e0259 0%, #dc78ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.news-row {
  padding-top: 3%;
  padding-bottom: 3%;
}

.news-card {
  box-shadow: 0px 0px 15px 0px #ffffff;
  background: #fff;
  border-radius: 10px;
  padding: 3%;
  margin-bottom: 3%;
}
.news-card h2 {
  font-family: "Advent Pro", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 26.31px;
  text-align: left;
  color: #000;
  padding-top: 2%;
  padding-bottom: 2%;
}

.news-card p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  color: #737373;
}

.news-card h5 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  color: #737373;
}

.live {
  background: linear-gradient(109.24deg, #000000 1.17%, #010018 100%);
  padding: 4%;
}

.live-row {
  padding-top: 4%;
}

.live-c {
  /* display: flex;
  justify-content: space-evenly; */
  align-items: center;
  background: linear-gradient(90deg, #ff6363 0%, #1548fc 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  border-radius: 25px;
  margin-bottom: 3%;
  padding: 1%;
}

.live-c h1 {
  font-family: "Alata", sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #fff;
}

.live-c h2 {
  font-family: "Alata", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.live-c button {
  padding: 8px 35px;
  background: linear-gradient(90deg, #ff6363 0%, #993b3b 100%);
  border: 2px solid #ff6363;
  border-radius: 15px;
  font-family: "Barlow", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

.live-c button:hover {
  border: 2px solid #fff;
  background: transparent;
  transition: all 0.5s ease-in-out;
}
.privacy {
  padding: 10%;
  background-image: url("../public/assets/scene.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Changa One", sans-serif;
  font-size: 72px;
  color: #fff;
}

.privacy-content {
  background: linear-gradient(159.09deg, #000000 0%, #010018 97.66%);
  color: #fff;
  font-family: "Inter", sans-serif;
  padding-bottom: 5%;
}

.privacy-content h3 {
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 2%;
}

.privacy-content p,
.privacy-content li {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact {
  padding: 10%;
  background-image: url("../public/assets/scene.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-header h2 {
  font-family: "Poppins", sans-serif;
  font-size: 48px;
  font-weight: 700;
  color: #fff;
}

.contact-header h1 {
  font-family: "Changa One", sans-serif;
  font-size: 48px;
  background-image: linear-gradient(90deg, #fa75fd 0%, #45c4e9 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.contact-content {
  background: linear-gradient(143.36deg, #000000 3.91%, #010018 91.1%);
  padding: 4%;
}
.contact-h h1 {
  font-family: "Barlow", sans-serif;
  font-size: 58px;
  font-weight: 700;
  text-align: center;
  color: #aad6ff;
}

.contact-h p {
  font-family: "Barlow", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: center;
  color: #fff;
}

.contact-form {
  background: #031e36;
  border-radius: 12px;
  border: 1px solid #a1abff;
  padding: 4%;
  margin-top: 4%;
}

.cform-item {
  margin-bottom: 4%;
}

.cform-item label {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 3%;
}

.cform-item input,
.cform-item textarea {
  border: 1px solid #a1abff;
  background: #d9d9d933;
  padding: 3%;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  width: 100%;
}

.cform-item input::placeholder,
.cform-item textarea::placeholder {
  color: #dadcf0;
}

.cform-item input:focus,
.cform-item textarea:focus {
  outline: none;
  box-shadow: none;
}

.contact-button button {
  background: linear-gradient(90deg, #1548fc 0%, #032498 100%);
  color: #fff;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  font-size: 20px;
  padding: 8px 30px;
  border: 0;
  border-radius: 30px;
}

.contact-button button:hover {
  /* background: linear-gradient(90deg, #032498 0%, #1548fc 100%); */
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}

.signin {
  background: linear-gradient(111.72deg, #2e0259 0%, #010018 97.74%);
  padding: 5%;

  min-height: 100vh;

  /* height: 100%; */

}
.thankyou-box{
  background: linear-gradient(111.72deg, #2e0259 0%, #010018 97.74%);
  padding: 2%;
}

.signin-form {
  background-image: url("../public/assets/signin.png");
  background-size: cover;
  padding: 5%;
  border-radius: 20px;
}
.thankyou-row{
  padding: 10px;
}
.signin-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-content: center; */
  padding: 5%;
}

.signin-left h1 {
  font-family: "Cagliostro", sans-serif;
  font-size: 72px;
  font-weight: 400;
  text-align: left;
  color: #fff;
}

.signin-left p {
  font-family: "Cantarell", sans-serif;
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  color: #fff;
}
.sign-heading h1 {
  font-family: "Cagliostro", sans-serif;
  font-size: 54px;
  font-weight: 400;
  text-align: left;
  color: #fff;
}

.sign-heading p {
  font-family: "Cantarell", sans-serif;
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  color: #fff;
}

.signin-right {
  padding: 5%;
  border-left: 2px solid #ffffff;
}

.sign-item {
  padding-left: 5%;
}

.pay-row {
  padding-top: 4%;
  padding-bottom: 4%;
}

.sign-item label {
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 2%;
  margin-top: 2%;
}

.pay-item {
  margin-bottom: 2%;
}

.pay-item label {
  font-size: 16px;
  font-weight: 500;
}

.sign-item input,
.sign-item select {
  width: 100%;
  padding: 3%;
  background: #d9d9d980;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  border: 0;
  border-radius: 20px;
}

.pay-item input {
  padding: 2%;
  font-size: 16px;
  border-radius: 6px;
}

.sign-item select option {
  color: #000;
}

.sign-item input::placeholder {
  color: #ededef;
}

.sign-item input:focus,
.sign-item select {
  box-shadow: none;
  outline: none;
  background: #d9d9d980;
  color: #fff;
}

.sign-group {
  background: #d9d9d980;
  border-radius: 20px;
}

.sign-group input {
  background: transparent;
}
.sign-group input:focus {
  background: transparent;
}

.sign-group span {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 20px;
  padding: 12px;
}

.sign-text i {
  color: white;
  cursor: pointer;
}

.sign-item p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: right;
  color: #fa75fd;
  margin-top: 2%;
}

.signin-button {
  padding-left: 5%;
}

.signin-button button {
  width: 100%;
  padding: 9px;
  background: linear-gradient(90deg, #993b3b 0%, #21124e 100%);
  border: 0;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-top: 4%;
  border-radius: 20px;
}

.signin-button button:hover {
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}

.signup-button button {
  /* width: 30%; */
  padding: 9px 55px;
  background: linear-gradient(90deg, #993b3b 0%, #21124e 100%);
  border: 0;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  border-radius: 20px;
}

.signup-button button:hover {
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}

.signin-right h6,
.signin-form h6 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  margin-top: 4%;
}

.signin-right h6 span,
.signin-form span {
  color: #fa75fd;
}

.booking-heading h1 {
  font-family: "Audiowide", sans-serif;
  font-size: 48px;
  font-weight: 400;
  text-align: center;
  color: #a1abff;
}

.book-row {
  padding: 5%;
}

.book-left {
  border: 0.5px solid #ffffff;
  background: #d9d9d91a;
  padding: 5%;
  margin-bottom: 4%;
}

.book-left h2 {
  font-family: "Kanit", sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #c1bfbf;
}
.book-left h3 {
  font-family: "Kanit", sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #d9d9d9;
}
.book-left h4 {
  font-family: "Kanit", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #d9d9d9;
}
.book-left span {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-left: 2%;
  margin-right: 2%;
}

.book-left h1 {
  font-family: "Jost", sans-serif;
  font-size: 26px;
  font-weight: 600;
  color: #fff;
  margin-top: 5%;
  margin-bottom: 5%;
}

.book-left li {
  font-family: "Inter", sans-serif;
  list-style: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  color: #fff;
  padding-bottom: 1%;
}

.book-right {
  border: 2px solid #ffffff;
  background: #d9d9d900;
  padding: 5%;
}

.book-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
}

.book-item h1 {
  font-family: "Jost", sans-serif;
  font-size: 26px;
  font-weight: 600;
  color: #fff;
}
.book-item h2 {
  font-family: "JInter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.book-item h3 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #45c4e9;
}

.book-counter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.book-counter i {
  color: #fff;
  font-size: 24px;
  padding: 2px 5px;
  border-radius: 50%;
  background-color: #a1abff;
  cursor: pointer;
}
.book-payment h3, .book-payment h4 {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #fff;
}
.terms-check label {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.payment-imgs {
  width: 100%;
  cursor: pointer;
}

.payment-imgs img {
  padding-right: 5%;
}

.book-button {
  display: flex;
  justify-content: center;
  margin-top: 8%;
  margin-bottom: 8%;
}

.book-button button {
  padding: 12px;
  width: 70%;
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  background: linear-gradient(90deg, #993b3b 0%, #2e0259 100%);
  border: 0;
  border-radius: 35px;
}

.book-button button:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.user {
  padding: 4%;
  background: linear-gradient(82.54deg, #21124e 15.98%, #9a2b41 88.42%);
}

.user-heading h1 {
  font-family: "Audiowide", sans-serif;
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  color: #fff;
}

.user-heading p {
  font-family: "Advent Pro", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.53px;
  text-align: center;
  color: #fff;
}

.confirm-img {
  display: flex;
  justify-content: center;
}

.confirm h1 {
  background-image: linear-gradient(90deg, #fa75fd 0%, #032498 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-family: "Changa One", sans-serif;
  font-size: 36px;
  font-weight: 400;
  text-align: center;
  padding: 3%;
}

.confirm h3 {
  font-family: "Changa One", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  color: #fff;
}

.confirm-button {
  display: flex;
  justify-content: center;
}

.confirm-button button {
  padding: 10px;
  width: 70%;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  background: linear-gradient(90deg, #993b3b 0%, #2e0259 100%);
  border: 0;
  border-radius: 20px;
  margin-top: 5%;
}

.confirm-button button:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.detail {
  background-image: url("../public/assets/coachella.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10%;
}

.detail-header h3 {
  font-family: "Chango", sans-serif;
  font-size: 28px;
  font-weight: 400;
  text-align: center;
  color: #fff;
}

.detail-header h1 {
  font-family: "Chango", sans-serif;
  font-size: 64px;
  text-align: center;
  color: #fff;
}
.detail-header h2 {
  font-family: "Changa One", sans-serif;
  font-size: 34px;
  font-weight: 400;
  text-align: center;
  color: #fff;
}

.details {
  background: linear-gradient(160.13deg, #000000 0%, #010018 78.72%);
  padding: 5%;
}

.detail-p p {
  font-family: "Changa", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  color: #fff;
}

.attract {
  background: #eebcff;
  display: flex;
  flex-wrap: wrap;
  margin-top: 3%;
}

.attract-left {
  background: #2e0259;
  border-radius: 0px 100px 200px 0px;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Audiowide", sans-serif;
  font-size: 28px;
  font-weight: 400;
  color: #fff;
}
.facilities-right {
  background: #2e0259;
  border-radius: 100px 0px 0px 200px;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Audiowide", sans-serif;
  font-size: 28px;
  font-weight: 400;
  color: #fff;
}

.facilities {
  display: flex;
  justify-content: space-between;
}

.attract-right {
  padding-left: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.attract-right li {
  list-style: none;
  font-family: "Kanit", sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 2%;
  color: #010033;
}

.event-detail {
  padding: 4%;
}

.event-h h1 {
  font-family: "Audiowide", sans-serif;
  font-size: 48px;
  font-weight: 400;
  text-align: center;
  color: #a1abff;
  margin-bottom: 4%;
}

.event-c {
  padding: 3%;
  background: linear-gradient(90deg, #993b3b 0%, #2e0259 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 90% 100%, 0% 100%);
}

.event-c h3 {
  font-family: "Poppins", sans-serif;
  font-size: 2p6x;
  font-weight: 700;
  color: #fff;
}

.event-c li {
  list-style: none;
  font-family: "Barlow", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.ticket-card {
  padding: 5%;
  border: 2px solid #a1abff;
  border-radius: 5px;
  margin-bottom: 3%;
}

.ticket-card h6 {
  font-family: "Alata", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #ffffffcc;
}

.ticket-card h1 {
  font-family: "Jost", sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  padding: 3%;
}

.ticket-card h2 {
  font-family: "Jost", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #ffffffcc;
  padding: 3%;
}

.ticket-card p {
  font-family: "Jost", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.12px;
  text-align: justify;
  color: #fff;
  padding-top: 4%;
  padding-bottom: 4%;
}

.ticket-card button,
.ticket-button button {
  padding: 8px 25px;
  border-radius: 25px;
  background: linear-gradient(90deg, #993b3b 0%, #2e0259 100%);
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  border: 0;
}

.ticket-card button:hover,
.ticket-button button:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.ticket-button {
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

/* .artists {
  padding: 4%;
}
.artist-card {
  position: relative;
  width: 100%;
}

.artist-card img {
  width: 100%;
  height: 400px;
  display: block;
}
.artist-card span h1 {
  font-family: "Changa One", sans-serif;
  font-size: 36px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  padding-bottom: 7%;
}
.artist-card span p {
  font-family: "Jost", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.01px;
  text-align: center;
  color: #fff;
}
.overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s;
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.artist-card:hover .overlay {
  opacity: 1;
}

.artist-card1 {
  padding: 5%;
}
.artist-cards {
  padding-left: 5%;
  padding-right: 5%;
  position: relative;
} */

.artists {
  padding: 4%;
}

.artist-card {
  position: relative;
  width: 100%;
}

.artist-card img {
  width: 100%;
  height: 400px;
  display: block;
}

.artist-card span h1 {
  font-family: "Changa One", sans-serif;
  font-size: 36px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  padding-bottom: 7%;
}

.artist-card span p {
  font-family: "Jost", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.01px;
  text-align: center;
  color: #fff;
}

.overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s;
  padding: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.artist-card:hover .overlay {
  opacity: 1;
}

.artist-card1 {
  padding: 5%;
}
.single-card img{
  object-fit: contain;
}
.artist-cards {
  padding-left: 5%;
  padding-right: 5%;
 
  position: relative;
}

/* Additional styles for slick slider */
.slick-slide {
  display: inline-block; /* Ensure slides are inline */
}

.slick-list {
  overflow: hidden; /* Hide overflow to avoid stacking issues */
}

.slick-track {
  display: flex; /* Use flex to align items in a row */
}

.events {
  background-image: url("../public/assets/bg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.event-i {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-right h1 {
  font-family: "Bungee Inline", sans-serif;
  font-size: 74px;
  font-weight: 400;
  color: #fff;
  text-align: center;
}

.event-right h2 {
  font-family: "Brawler", sans-serif;
  font-size: 48px;
  font-weight: 400;
  color: #fdfbfb;
  text-align: center;
}

.event-right button {
  padding: 8px 50px;
  background: linear-gradient(90deg, #ff6363 0%, #fa75fd 100%);
  border-radius: 25px;
  border: 0;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-top: 3%;
  margin-bottom: 5%;
}

.event-right button:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.event-main {
  background: linear-gradient(157.16deg, #000000 9.77%, #010018 81.85%);
  padding: 5%;
  min-height: 100vh;
}

.event-search {
  display: flex;
  align-items: center;
  position: relative;
}
.event-search h3 {
  color: #fff;
}

.calendar {
  position: absolute;
  top: 120%;
  right: 14%;
}

/* .calendar-relative {
  position: relative;
} */

.calendar-labels {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  position: absolute;
  top: 2%;
  left: 5%;
  z-index: 1;
  padding-left: 11%;
  padding-right: 11%;
}

.calendar-labels label span {
  color: #1F5EFF;
}

.calendar-labels label {
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  color: #fff;
}

.calendar-labels label:last-child {
  text-align: right;
}

.calendar-column {
  position: absolute;
  z-index: 1;
  bottom: 4%;
  left: 12%;
}
.calendar-button{
  display: flex;
  gap: 15px;
}

.calendar-button button {
  padding: 2px 10px;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  border-radius: 5px;
}

.calendar-button button:hover {
  border: 1px solid #1F5EFF;
  background: #2C437B;
  transition: all 0.3s ease-in-out;
}

.react-datepicker{
  padding: 7%;
  padding-top: 20%;
  padding-bottom: 10%;
  background: #323741 !important;
}

.react-datepicker__day--today {
  background-color: #2C437B !important;
  border: 2px solid #1F5EFF !important;
  border-radius: 8px !important;
}
.react-datepicker__day--keyboard-selected{
  border-radius: 8px !important;
  background: #1F5EFF !important;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select){
  background: #323741;
}
.react-datepicker__month {
  padding-top: 5%;
  padding-bottom: 5%;
}

.react-datepicker__day:hover {
  background-color: #2C437B !important;
}

/* for month name */
.react-datepicker__current-month{
  background: #323741;
  color: #fff !important;
  font-family: "Montserrat", sans-serif;
  padding: 5%;
}

.react-datepicker__day-names {
  border: 0 !important;
  padding-top: 5%;
  padding-bottom: 5%;
}
.react-datepicker__day-name {
  color: #656A78 !important;
}

/* for text of dates */
 .react-datepicker__day, .react-datepicker__time-name{  
  background: #323741;
  color: #fff !important;
  font-family: "Poppins";
  font-weight: 500 !important;
}
.react-datepicker__navigation--previous{
  top: 17% !important;
  left: 10% !important;
}
.react-datepicker__navigation--next{
  top: 17% !important;
  right: 10% !important;
}

.event-group {
  background: #d9d9d926;
  width: 80% !important;
  border: 1px solid #ffffff;
  border-radius: 25px;
  padding: 0.5%;
}

.event-group input {
  background: transparent;
  border: 0;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.event-group input:focus {
  box-shadow: none;
  outline: 0;
  background: transparent;
  color: white;
}

.event-group input::placeholder {
  color: #ffffff9d;
}

.event-append span {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 20px;
}

.filter-icons {
  display: flex;
  margin-left: 4%;
}

.filter-icons i {
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

.filter-icons i:hover {
  color: #dc78ff;
}

.event-month {
  display: flex;
  justify-content: space-between;
  padding: 4%;
} 

.event-month i {
  color: #fff;
  font-size: 30px;
}

.event-month i:first-child {
  padding-right: 8%;
}

.event-month span {
  font-family: "Audiowide", sans-serif;
  font-size: 36px;
  font-weight: 400;
  color: #fff;
  font-style: normal !important;
}

.event-cards {
  padding-top: 4%;
}

.event-card {
  border: 1px solid #ffffff;
  padding: 3%;
  margin-bottom: 4%;
}

.event-card h2 {
  font-family: "Audiowide", sans-serif;
  font-size: 32px;
  font-weight: 400;
  color: #fff;
}

.event-card h4,
.event-card h3 {
  font-family: "Kanit", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}
.event-card h1 {
  font-family: "Kanit", sans-serif;
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  padding-bottom: 14%;
}

.event-card p {
  font-family: "Kanit", sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #d9d9d980;
}

.event-card img {
  width: 100%;
}

.event-card h5 {
  font-family: "Changa", sans-serif;
  font-size: 24px;
}

.event-card h5:hover {
  color: #fa75fd;
}

.content4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-button {
  padding-top: 2%;
}
.event-box{
display:flex ;
justify-content:center ;
}
.event-button p{
  color: #fff;
  font-family: 'poppins';
  font-size: 14px;
  margin: 10px 8px;
}
.event-button button {
  padding: 10px 45px;
  background: linear-gradient(90deg, #d9d9d9 0%, #737373 100%);
  border: 0;
  color: #010018;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.event-button button:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.filters {
  background: #d9d9d926;
  border-radius: 20px;
  padding: 3%;
  transition: all 0.3s ease-in-out;
  margin-top: 3%;
}

.filters h1 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.filters select, .filters input {
  width: 100%;
  padding: 2%;
  background: #aad6ff33;
  font-family: "Jost", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  border: 0.5px solid #656a78;
  padding-left: 5% !important;
}

.filters input::placeholder {
  color: #fff;
}

.filters select option {
  background: #d9d9d91a;
  border: 0.5px solid #656a78;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.filter-select {
  display: flex;
  justify-content: space-between;
  background: #45c4e966;
  border-radius: 25px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: white;
  padding: 3%;
}

.filter-select span {
  padding-left: 4%;
}
.filter-select i {
  padding-right: 4%;
  cursor: pointer;
}



.home {
  position: relative;
  padding-left: 5%;
  padding-right: 5% !important;

  padding-top: 2%;
  left: 230px;
  width: calc(100% - 230px);
  /* width: 100%; */
  min-height: 100vh;
  transition: all 0.5s ease;
  background: linear-gradient(157.16deg, #000000 9.77%, #010018 81.85%)
}

.sidebar.close ~ .home {
  left: 78px;
  width: calc(100% - 78px);
  padding-left: 4%;
  padding-right: 2%;
}


.home .toggle-sidebar {
  height: 60px;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}

.home .toggle-sidebar i {
  font-size: 25px;
  margin-left: 15px;
}

.home .toggle-sidebar .text {
  font-size: 25px;
  font-weight: 600;
}

.dashboard {
  padding-top: 0%;
  padding-bottom: 5%;
  min-height: 100vh;
}

.dashboard-header h1 {
  font-family: "Poppins", sans-serif;
  font-size: 22.17px;
  font-weight: 600;
  color: #fff;
}
.dashboard-header {
  padding-bottom: 1%;
  border-bottom: 1px solid #ffffff54;
}

.dash-row {
  padding-top: 3%;
  padding-bottom: 3%;
}

.dash-card {
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border-radius: 16px;
  background: #fff;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
  margin-bottom: 4%;

}

/*----------------- SIDEBAR CSS------------------------ */
.sidebar {
  position: fixed;
  top: 0%;
  left: 0;
  height: 100%;
  width: 260px !important;
  background: linear-gradient(83.22deg, #2e0259 0%, #010033 100%);
  color: #fff;
  transition: all 0.5s ease;
  z-index: 100;
  border-radius: 0px 10px 10px 0px;
}

.sidebar.close {
  width: 120px !important;
}

/* --------- Logo ------------ */

.logo-box {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}

.logo-box img {
  margin-top: 5%;

  margin-left: 5%;
}
.sidebar.close .logo-box i {
  transform: rotate(180deg);
}

/* ---------- Sidebar List ---------- */
.sidebar-list {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto !important;
}

.sidebar-list::-webkit-scrollbar {
  display: none;
}

.sidebar-list li {
  transition: all 0.5s ease;
}

.sidebar-list li .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
  cursor: pointer;
  color: #fff;
}

.sidebar-list li.active .title {
  background-color: #a952a3;
  color: white;
}

.sidebar-list li.active .bi-chevron-down {
  transition: all 0.5s ease;
  transform: rotate(180deg);
}

.sidebar-list li .title .link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar-list li .title i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #292d32;
  font-size: 20px;
  margin-left: -20px;
}
.sidebar-list li .title svg {
  margin-right: 25px;
  height: 50px;
  width: 23px;
  fill: #292d32;
  margin-left: 10px;
}

.sidebar-list li .title .name {
  font-size: 14px;
  font-weight: 400;
  color: #fff !important;
}

/* ---------------- Submenu ------------- */
.sidebar-list li .submenu {
  display: none;

  width: 0;
  height: 0;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar-list li.active .submenu {
  width: unset;
  height: unset;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 6px 6px 14px 80px;
  background-color: #eeeeee;
}

.sidebar-list li.dropdown.active .submenu {
  width: unset;
  height: unset;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 6px 6px 14px 80px;
  background-color: #eeeeee;
}

.submenu .link {
  color: #000;
  font-size: 15px;
  padding: 5px 0;
  transition: all 0.5s ease;
  text-decoration: none;
}

.submenu-title {
  display: none;
}

/* ---------------- Submenu Close ------------- */
.sidebar.close .logo-name,
.sidebar.close .title .name,
.sidebar.close .title .bi-chevron-down,
.sidebar.close .side-profile .side-name {
  display: none;
}

.sidebar.close .sidebar-list {
  overflow: visible;
}

.sidebar.close .sidebar-list li {
  position: relative;
}

.sidebar.close .sidebar-list li .submenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  height: max-content;
  width: max-content;
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
  color: #000;
}

.sidebar.close .sidebar-list li:hover .submenu {
  opacity: 1;
  top: 0;
  pointer-events: initial;
  background-color: #1b59f8;
}

.sidebar.close .submenu-title {
  display: block;
  font-style: 18px;
}

.maintable-column {
  background-color: #000;
  border: 2px solid #dbe1fa1f;
  border-radius: 8px;
  padding: 20px;
  margin-top: 4% !important;
}
.foot-tablerow h5 {
  font-size: 25px;
  margin: 15px 25px;
  color: #d8d7d7;
}
.table select {
  width: 100px;
  height: 30px;
}

.table {
  width: 100%;
}
.tr1 {
  background: #000 !important;
}
.table th {
  background-color: #000 !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;

  color: #fff !important;
  padding: 12px !important;
  text-align: center !important;
}
.table td {
  background-color: #000 !important;
  font-weight: 400;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  color: #c0c8e6 !important;
  padding: 10px 13px !important;
  text-align: center !important;
  width: auto;
}

.first {
  width: 25%;
}

.second {
  width: 15%;
}

.ellipsis { 
  position: relative; 
} 
.ellipsis:before { 
  content: ' '; 
  visibility: hidden; 
} 
.ellipsis abbr { 
  position: absolute; 
  left: 0; 
  right: 0; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}


div.dt-length label, div.dt-search label, div.dt-info {
  color: #fff;
}
div.dt-search input {
  background: #ffffffa6;
}

div.dt-search input:focus {
  box-shadow: none;
  outline: 0;
}

div.dt-search input::placeholder {
  color: black;
}

td i {
  color: #0d6efd;
  padding: 5px;
  background: #d8e8ff;
  border-radius: 5px;
  cursor: pointer;
}

.dt-column-order {
  display: none !important;
}
.modal-content {
  background: linear-gradient(83.22deg, #2e0259 0%, #010033 100%);
}

.modal-content1 {
  color: #fff;
}

.modal-content1 input {
  background: white;
  font-family: "Jost", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #000;
  border: 0.5px solid #656a78;
  padding: 2%;
  width: 60%;
  border-radius: 5px;
}

.modal-box {
  padding: 2%;
}

.modal-box label {
  font-family: "Jost", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  display: block;
  margin-bottom: 2%;
}

.modal-box input {
  background: white;
  font-family: "Jost", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #000;
  border: 0.5px solid #656a78;
  padding: 2%;
  width: 100%;
  border-radius: 5px;
}

.modal-box input::placeholder {
  color: #031e36;
}

.modal-box input:disabled {
  background: #a5a5a5;
}
.modal-title { 
  color: #fff !important;
  font-size: 22px !important;
  font-family: "Poppins", sans-serif;
}
.modal-header button {
  color: #fff !important;
}

.modal-header {
  border-bottom: 0.5px solid #656a78 !important;
}
.modal-footer {
  border-top: 0.5px solid #656a78 !important;
}

.modal-content1 button {
  background: transparent;
  color: white;
  font-family: "Jost", sans-serif;
  font-size: 14px;
  font-weight: 500;
  border: 0;
}

.find-event{
  background: linear-gradient(90deg, #2e0259 0%, #032498 100%);
  border: 0;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 25px;
  border-radius: 25px;
}

.find-event:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}
.find-event-delete{
  background: #cf0101;
  border: 0;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 25px;
  border-radius: 25px;
}

.find-event-delete:hover {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.modal-header .btn-close {
  color: #fff !important;
}

.tab {
  display: flex;
  justify-content: center;
  margin-top: 3%;
  margin-bottom: 3%;
  /* padding: 1%; */
  border-bottom: 1px solid #a952a3;
}

.tab button {
  background: transparent;
  border: 0;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 1%;
}

.tab button:hover {
  color: #a952a3;
  border-bottom: 4px solid #a952a3;
 
}
.tab button.active {
  color: #a952a3 !important;
  border-bottom: 4px solid #a952a3;
 
}

.tabcontent {
  display: none; 
  padding: 0px 12px;
}

.tabcontent.active {
  display: block; 
}

.tab-row {
  padding: 4%;
}

.tab-card {
  padding: 4%;
  border-radius: 10px;
  background: #7885fc7e;
  border: 1px solid #1F5EFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab-left h2 {
  color: #fff;
  font-family: "Audiowide", sans-serif;
  font-size: 24px;
  font-weight: 500;
}
.tab-left h6 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0;
}
.tab-right h4 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
}
.tab-right {
  display: flex;
}


.tab-col {
  padding-right: 3% !important;
  padding-bottom: 3%;
}

.filter-column button {
  border: 0;
  background: linear-gradient(90deg, #2e0259 0%, #032498 100%);
  border-radius: 25px;
  color: white;
  font-family: "poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 20px;
}

.filter-column button:hover {
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}

.form-button {
  padding-top: 4%;
  padding-right: 5%;
  display: flex;
  justify-content: end;
}

.admin-row {
  padding-right: 5%;
  padding-left: 2%;
  padding-top: 3%;
}

.admin-item {
  margin-bottom: 4%;
}

.admin-item label {
  font-family: "Jost", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  display: block;
  margin-bottom: 2%;
}

.admin-item input, .admin-item select, .admin-item textarea {
  background: rgba(255, 255, 255, 0.74);
  font-family: "Jost", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #000;
  border: 0.5px solid #656a78;
  padding: 2%;
  width: 100%;
  border-radius: 5px;
}

.admin-item input:focus {
  border: 0;
  box-shadow: none;
  outline: none;
}

.admin-item input::placeholder, .admin-item textarea::placeholder {
  color: #000;
}

.loading-bg {
  background: linear-gradient(159.09deg, #000000 0%, #010018 97.66%);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-bg h3 {
  background-image: linear-gradient(90deg, #9d4af0 0%, #dc78ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 18px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin-top: 2%;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 80px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #25b09b;
  animation: l15 1s infinite linear;
}
.loader::before,
.loader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 4s infinite;
}
.loader::after {
  margin: 8px;
  animation-duration: 3s;
}
@keyframes l15{ 
  100%{transform: rotate(1turn)}
}


.error {
  width: 80px;
  height: 80px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #b04825; /* Change this color to match your design */
  border-radius: 50%;
}
.error h3{
  color:#b04825 !important ;
}

.error::before,
.error::after {
  content: "";
  position: absolute;
  width: 60%;
  height: 4px;
  background-color: #b04825; /* Change this color to match your design */
}

.error::before {
  transform: rotate(45deg);
}

.error::after {
  transform: rotate(-45deg);
}

.CookieConsent {
  align-items: center !important;
}
